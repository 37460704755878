<template>
    <div>
      <ParallaxComponent 
        :selectedImage="selectedImage"
        :title="'Experiences'"
        :paragraph="'Experience the magic of the African wild with Acacia Wilderness Mweya where your experience begins right from the doorsteps of your room'"
      />
    
      <section>
        <article class="accommodation-body">
          <section>
            <article>
              <div class="room-accommodation-grid">
                <div>
                  <img src="../../assets/images/experiences1.jpg" />
                </div>
                <div class="d-flex align-items-center">
                  <div>
                    <h2  style="font-size: 3em">Game Drives</h2>
                    <p>Enjoy your own private space amidst nature</p>
                    <p>
                      Immerse yourself in the beauty of Queen Elizabeth National Game Park with a variety of activities for adventure and relaxation. 
                      Enjoy guided game drives, boat safaris on the Kazinga Channel, bird watching, and nature walks. 
                      There's something for everyone to create unforgettable memories.
                    </p>
                  </div>
                </div>
              </div>
  
              <div class="room-accommodation-grid-reserve">
                <div class="d-flex align-items-center">
                  <div>
                    <h2>Bird Watching</h2>
                    <p>Perfect for friends, colleagues, or couples seeking a romantic retreat.</p>
                    <p>
                      Queen Elizabeth National Park is a birdwatcher's paradise, boasting one of the highest biodiversity ratings of any protected area in the world. 
                      With over 600 bird species recorded, the park offers a remarkable variety of habitants that support a wide range of avian life.
                    </p>
                  </div>
                </div>
                <div>
                  <img src="../../assets/images/experiences2.jpg" />
                </div>
              </div>
  
              <div class="room-accommodation-grid">
                <div>
                  <img src="../../assets/images/experiences5.jpg" />
                </div>
                <div class="d-flex align-items-center">
                  <div>
                    <h2>Experiential Lion Tracking</h2>
                    
                    <p>Perfect for friends, colleagues, or couples seeking a romantic retreat.</p>
                    <p>
                      Queen Elizabeth National Park is a birdwatcher's paradise, boasting one of the highest biodiversity ratings of any protected area in the world. 
                      With over 600 bird species recorded, the park offers a remarkable variety of habitants that support a wide range of avian life.
                    </p>
                  </div>
                </div>
              </div>
            </article>
          </section>
        </article>
      </section>
  
      <section>
        <article class="experiences-gallery">
            <div class="acacia-container">
                <div class="text-center">
                    <h2>Gallery</h2>
                    <p>Capture the beauty</p>
                </div>
                <div class="experiences-gallery-grid">
                    <div>
                      <img src="../../assets/images/experiences/gallery/image 17.jpg" alt="">
                    </div>
                    <div>
                      <img src="../../assets/images/experiences/gallery/image 18.jpg" alt="">
                    </div>
                    <div>
                      <img src="../../assets/images/experiences/gallery/image 19.jpg" alt="">
                    </div>
                    <div>
                      <img src="../../assets/images/experiences/gallery/image 22.jpg" alt="">
                    </div>
                    <div>
                      <img src="../../assets/images/experiences/gallery/image 23.jpg" alt="">
                    </div>
                    <div>
                      <img src="../../assets/images/experiences/gallery/large1.png" alt="">
                    </div>
                    <div>
                      <img src="../../assets/images/experiences/gallery/large2.png" alt="">
                    </div>
                    <div>
                      <img src="../../assets/images/experiences/gallery/lion.jpg" alt="">
                    </div>
                    <div>
                      <img src="../../assets/images/experiences/gallery/image 17.jpg" alt="">
                    </div>
                </div>
            </div>
        </article>
      </section>
    </div>
  </template>
  
  <script>
  import ParallaxComponent from "../../components/website/pallarax-component.vue";
  export default {
  components: {
    ParallaxComponent
  },

    data() {
      return {
        selectedImage: require("../../assets/images/experiences3.jpg"),
      };
    },
  };
  </script>
  
  <style scoped>
  .experiences-gallery {
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .acacia-container {
    width: 100%;
    padding-left: 8%;
    padding-right: 8%;
  }
  .accommodation-header {
      background-image: url('../../assets/images/experiences3.jpg');
      background-attachment: fixed;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      height: 100vh;
      width: 100%;
  
      position: relative;
  }
  
  .accommodation-header > div{
    background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.878) 100%
  );
    color: white;
    position: absolute;
    top: 0px;
    bottom: 0px;
    width: 100%;
  
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 180px;
  }
  
  .accommodation-header > div>div{
    width: 60%;
  }
  .accommodation-header h1{
    font-size: 3em;
    font-weight: 800;
  }
  .accommodation-header p{
    font-size: 1.5em;
    font-weight: 500;
  }
  
  .accommodation-body{
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .accommodation-body h2{
    color: #17351E;
    
    font-size: 2em;
    font-weight: 500;
  }
  
  .room-accommodation-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  
  .room-accommodation-grid > div{
    min-height: 80vh;
  }
  
  .room-accommodation-grid > div p{
    font-size: 1.2em;
  }
  
  .room-accommodation-grid > div img{
    min-height: 80vh;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  
  .room-accommodation-grid > div:nth-child(2){
    padding: 50px;
  }
  
  .room-accommodation-grid-reserve {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  
  .room-accommodation-grid-reserve > div{
    min-height: 80vh;
  }
  .room-accommodation-grid-reserve > div p{
    font-size: 1.2em;
  }
  
  .room-accommodation-grid-reserve > div img{
    min-height: 80vh;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  
  .room-accommodation-grid-reserve > div:first-child{
    padding: 50px;
  }
  .acacia-btn {
    background-color: #17351E;
    border: 2px solid #17351E;
    color: #FFF;
    border-radius: 0px;
    font-size: 1.2em;
    margin: 0px !important;
    transition: 0.5s;
    width: 50%;
  }
  
  .acacia-btn:hover {
    background-color: #DDD0A9;
    border: 2px solid #DDD0A9;
    color: white !important;
    transition: 0.5s;
  }
  
  .room-rate{
    width: 50%;
    margin-bottom: 30px;
  }
  
  .room-rate >div{
    border: 1px solid #DDD0A9;
    padding: 20px;
    font-size: 1.5em;
    font-weight: 600;
    text-align: center;
  }
  
  .room-rate >div:first-child{
    background-color: #DDD0A9;
    color: white;
  }

  .experiences-gallery-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }
  .experiences-gallery-grid >div{
    width: 100%;
    height: 200px;
    background-color: #DDD0A9;
  }
  .experiences-gallery-grid >div img{
    width: 100%;
    height: 200px;
    object-fit: cover;
    background-color: #DDD0A9;
  }
  /*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
  @media (min-width: 768px) and (max-width: 1024px) {
  }
  
  /*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  }
  
  /*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
  @media (min-width: 481px) and (max-width: 767px) {
    
  }
  
  /*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
  @media (min-width: 320px) and (max-width: 480px) {
    
  }
  </style>
  